const es_localization = {
    name: "es",
    menus: {
        actividades: "Actividades",
        controles: "Controles",
        planesAccion: "Planes de acción",
        actividadesVencidas: "Act. vencidas",
        activosInformacion: "Activos de información",
        calendario: "Calendario",
        cerrarSesion: "Cerrar sesión",
        compromisos: "Compromisos",
        procesos: "Procesos",
        configuraciones: "Configuraciones",
        dashboard: "Dashboard",
        documentos: "Documentos",
        oficios: "Oficios",
        indicadores: "Indicadores",
        eventoRiesgos: "Eventos de riesgo",
        mapaCalor: "Mapa de calor",
        miPerfil: "Mi perfil",
        misActividades: "Mis Actividades",
        misControles: "Mis controles",
        misIndicadores: "Mis Indicadores",
        exportacion: "Exportación",
        repProximoVencimiento: "Próximo vencimiento",
        repCumplimiento: "Reporte cumplimiento",
        repDocumento: "Reporte documentos",
        repRiesgos: "Reporte de riesgos",
        repIndicadores: "Reporte de indicadores",
        repOficios: "Reporte de oficios",
        repEventosRiesgos: "Reporte eventos riesgos",
        atrasos: "Atrasos",
        repControles: "Reporte controles",
        repPlanesAccion: "Reporte planes de acción",
        repActivos: "Reporte activos",
        reportes: "Reportes",
        riesgos: "Riesgos",
        misRiesgos: "Mis riesgos",
        asignadorOficios: "Asignador Oficios",
        misOficios: "Mis oficios",
        gestorCodigos: "Gestor de códigos",
        reasignaciones: "Reasignaciones",
        informes: "Informes",
        timeReport: "TimeReport",
        timeReportMisReportes: "TimeReport Mis reportes",
        marcaciones: "Marcaciones",
        reportesJornadas: "Reportes de jornadas",
        tareasCortas: "Tareas cortas",
        misTareasCortas: "Mis tareas cortas",
        auditoria: "Auditoría",
        proyectos: "Proyectos",
        cumplimiento: "Cumplimiento",
        solicitudesProrroga: "Solicitudes prórroga",
        extras: "Extras",
        solicitudesDocumentos: "Solicitudes documentos",
        assessment: "Evaluaciones",
        misEvaluaciones: "Mis evaluaciones",
        planesAuditoria: "Planes de auditoría",
        auditorias: "Auditorías",
        misAuditorias: "Mis auditorías",
        misPruebasAuditorias: "Mis pruebas de auditoría",
        misRecomendaciones: "Mis recomendaciones",
        misCompromisos: "Mis compromisos",
        misHorasAuditoria: "Mis horas de auditoría",
        reglasAuditoria: "Reglas de auditoría",
        repReglas: "Reporte de reglas",
        misReglasAuditoria: "Mis reglas auditoría",
        misprorrogasCompromisos: "Mis prórrogas",
        prorrogasPendientes: "Prórrogas pendientes",
        misHallazgos: "Mis hallazgos",
    },
    components: {
        sistema: {
            abril: "abril",
            agosto: "agosto",
            agregar: "Agregar",
            agregarNuevoComentario: "Agrega un nuevo comentario",
            cancelar: "Cancelar",
            aceptar: "Aceptar",
            confirmacion: "Confirmación",
            comentario: "Comentario",
            crearComentarioExitoso: "El sistema guardó exitosamente el comentario ingresado",
            descargarAarchivo: "Descargar archivo",
            desde: "Desde",
            diciembre: "diciembre",
            domingo: "domingo",
            elementoNoEncontrado: "Elemento no encontrado.",
            eliminar: "Eliminar",
            enero: "enero",
            febrero: "febrero",
            hasta: "Hasta",
            historial: "Historial",
            historialYcomentarios: "Historial y comentarios",
            jueves: "jueves",
            julio: "julio",
            junio: "junio",
            lunes: "lunes",
            martes: "martes",
            marzo: "marzo",
            mayo: "mayo",
            miercoles: "miércoles",
            notificarFeriados: "Notificar feriados",
            noviembre: "noviembre",
            octubre: "octubre",
            ocurrioErrorRecInfo: "Ocurrió un error mientras se recuperaba la información.",
            sabado: "sabado",
            septiembre: "septiembre",
            validarCaracteresComentario: "El comentario debe tener al menos 5 caracteres.",
            viernes: "viernes",
            vistaPrevia: "Vista previa",
            vistaPreviaNoDisponible: "Vista previa no disponible",
            seleccion: "Selección",
            seleccionados: "Seleccionados",
            todosItemsSeleccionados: "Todos los items seleccionados",
            seleccionado: "Seleccionado",
            sinResultados: "Sin resultados",
            aLas: "A las",
            timeAgoDe: " de",
            ahora: "Ahora",
            seg: "seg",
            haceUnMin: "hace un min",
            minutos: "minutos",
            hoy: "Hoy",
            ayer: "Ayer",
            nuevoElemento: "Nuevo elemento",
        },
        materialTable: {
            header: {
                actions: 'Acciones'
            },
            pagination: {
                labelRowsSelect: 'filas',
                firstTooltip: 'Primera página',
                lastTooltip: 'Última página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Página siguiente',
                labelDisplayedRows: '{from}-{to} de {count}',
            },
            toolbar: {
                searchPlaceholder: 'Buscar...',
                searchTooltip: 'Buscar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar como CSV",
                exportPDFName: "Exportar como PDF",
                nRowsSelected: '{0} fila(s) seleccionada(s)',
            },
            body: {
                emptyDataSourceMessage: 'No hay registros que mostrar',
                editRow: {
                    deleteText: '¿Estás seguro de eliminar esta fila?',
                    cancelTooltip: 'Cancelar',
                    saveTooltip: 'Aceptar',
                },
                addTooltip: 'Añadir',
                deleteTooltip: 'Eliminar',
                editTooltip: 'Editar',
            }
        },
        gantt: {
            tarea: "Tarea",
            fechaInicio: "Fecha inicio",
            fechaFin: "Fecha término",
            enero: "enero",
            febrero: "febrero",
            marzo: "marzo",
            abril: "abril",
            mayo: "mayo",
            junio: "junio",
            julio: "julio",
            agosto: "agosto",
            septiembre: "septiembre",
            octubre: "octubre",
            noviembre: "noviembre",
            diciembre: "diciembre",
        },
        arbol: {
            guardar: "Guardar",
        }
    },
    modules: {
        accesos: "Accesos",
        acciones: "Acciones",
        act: "Act.", //Actual
        actAfectaActivo: "¿Actividad afecta a un activo de información?",
        actAfectaRiesgo: "¿Actividad afecta a un riesgo?",
        actAtiempo: "Actividades a tiempo",
        actAtrasadas: "Actividades atrasadas",
        actAtrasadasPorVisar: "Actividades atrasadas por visar",
        actAunNoHanSidoCerradas: "Actividades que aún no han sido cerradas",
        actEnCurso: "Actividades en curso",
        actEntregaFormaAtrasada: "Actividades entregadas de forma atrasada",
        actEsperaAprobacion: "Actividades a la espera de aprobación",
        actEsperaSerVisadas: "Actividades en espera ser visadas",
        actHabCuandoActNivSupSeaCompletada: "La actividad se habilitará cuando una actividad de nivel superior sea completada",
        actQueSeEncuentranAtrasadas: "Actividades que se encuentran atrasadas",
        actSiendoCursadas: "Actividades siendo cursadas",
        actTotEjecucionAtrasada: "Actividades totales en estado de ejecución atrasadas",
        actTotEnEstadoEjecAtrasasas: "Actividades totales en estado de ejecución atrasadas",
        actTotEnProcVisacionAtrasada: "Actividades totales en proceso de visación atrasadas",
        actTotEsperaAprob: "Actividades totales a la espera de aprobación",
        actTotProcVisacionAtrasadas: "Actividades totales en proceso de visación atrasadas",
        actTotRechazadas: "Actividades totales rechazadas",
        actTotSiendoCursadas: "Actividades totales siendo cursadas",
        actVencidas: "Actividades vencidas",
        actVisadasAtiempo: "Actividades visadas a tiempo",
        actVisadasConRetraso: "Actividades visadas con retraso",
        activadesAsociadas: "Actividades asociadas",
        actividad: "Actividad",
        actividadCancelada: "Actividad cancelada",
        actividadCompletada: "Actividad Completada",
        actividadSinTerminar: "Actividad sin terminar",
        actividades: "Actividades",
        actividadesEnCurso: "Actividades en curso",
        activo: "Activo",
        activoInfAsociado: "Activo de información asociado",
        activoInformacion: "Activos de información",
        activosDeInformacion: "Activos de información",
        adjuntos: "Adjuntos",
        adjuntosRespuesta: "Adjuntos respuesta",
        adminAct: "Administrar actividades",
        adminCtrl: "Administrar controles",
        adminRiesgo: "Administrar riesgos",
        agregar: "Agregar",
        ajustesUsuario: "Ajustes de usuario",
        ant: "Ant.",
        anterior: "Anterior",
        anual: "Anual",
        aporteConfidencialidad: "Aporte de confidencialidad",
        aporteDisponibilidad: "Aporte de disponibilidad",
        aporteIntegridad: "Aporte de integridad",
        aprobarResidual: "Aprobar residual",
        archivo: "Archivo",
        archivos: "Archivos",
        archivoExcel: "Archivo Excel",
        area: "Área",
        areaDoc: "Área de documento",
        areaDocumentos: "Área documentos",
        areaResponsable: "Área responsable",
        areas: "Áreas",
        areasAuditoria: "Áreas auditoría",
        asignacionActividad: "Asignación de actividad",
        asignacionActividadPorRep: "Asignación de actividad por repetición",
        asignacionLectorDocumento: "Asignación lector de documento",
        atrasada: "Atrasada",
        atrasadas: "Atrasadas",
        atrasadasEjecutores: "Atrasadas ejecutores",
        atrasadasPorAprobar: "Atrasadas por aprobar",
        atrasadasPorEjecutar: "Atrasadas por ejecutar",
        atrasadasPorVisar: "Atrasadas por visar",
        atrasadasVisadores: "Atrasadas visadores",
        atrasos: "Atrasos",
        asignar: "Asignar",
        asignacionMasiva: "Asignación masiva",
        asignacionMasivaOficios: "Asignación masiva de oficios",
        automatizacion: "Automatización",
        automatizacionControl: "Automatización de control",
        autor: "Autor",
        avisoCierre: "Aviso cierre",
        avisoCierreActividad: "Aviso cierre de actividad",
        avisoDiarioActividad: "Aviso diario de actividades",
        avisoPrevio: "Aviso previo",
        avisoVisacionActividad: "Aviso visación de actividad",
        azureBlob: "Azure blob",
        baseDeDatos: "Base de datos",
        bibliotecaDocumentos: "Biblioteca de documentos",
        bibliotecaEvidencias: "Biblioteca de evidencias",
        borrarExitoso: "Operación realizada con éxito. Elemento eliminado",
        buenasNoches: "¡Buenas noches",
        buenasTardes: "¡Buenas tardes",
        buenosDias: "¡Buenos días",
        cada: "Cada",
        calcularVenc: "Calcular vencimiento",
        calendario: "Calendario",
        canalRecepcion: "Canal recepción",
        canalesEnvio: "Canales de envío",
        canalesRecepcion: "Canales de recepción",
        cancelarActividad: "Cancelar actividad",
        cancelarControl: "Cancelar control",
        cargaMasiva: "Carga masiva",
        cargaMasivaOficios: "Carga masiva de oficios",
        cargadoPreviamente: "Cargado previamente:",
        cargos: "Cargos",
        cargar: "Cargar",
        cargaExitosa: "Carga realizada con éxito",
        causa: "Causa",
        cerrar: "Cerrar",
        cerrarActividades: "Cerrar actividades",
        cerrarExitosoActividad: "El sistema cerró exitosamente la actividad",
        ciclo: "Ciclo",
        ciclosProcesos: "Ciclos de procesos",
        cierreDeActividad: "Cierre de actividad",
        cierreMasivo: "Cierre masivo",
        cierreMasivoExitosoActividad: "Cierre masivo realizado",
        cita: "Cita",
        citas: "Citas",
        clasificacion: "Clasificación",
        clasificacionDocumentos: "Clasificación de documentos",
        clasificaciones: "Clasificaciones",
        codAct: "Código de actividad",
        codActPlaceholder: "Ingrese un código",
        codigo: "Código",
        comentario: "Comentario",
        comentarioCierre: "Comentario de cierre",
        comentarioConsecuencia: "Comentario consecuencia",
        comentarios: "Comentarios",
        comentariosVisador: "Comentarios visador",
        compProc: "Comp./Proc.",
        completar: "Completar",
        comprobarConexion: "Comprobar conexión",
        compromiso: "Compromiso",
        compromisoOproceso: "compromiso o proceso",
        compromisos: "Compromisos",
        conectarConSharepoint: "Conectar con SharePoint",
        conexionExitosa: "Conexión exitosa",
        conexionFallida: "Conexión fallida",
        confidencialidad: "Confidencialidad",
        configBibliotecas: "Config. de bibliotecas",
        configCorreos: "Config. de correos",
        configEtiquetas: "Config. de etiquetas",
        configGenerales: "Config. generales",
        configHorariosCorreos: "Config. de horarios de correos",
        configMapaCalor: "Config. de mapa de calor",
        configNotificaciones: "Config. de notificaciones",
        configRiesgo: "Configuración de riesgo",
        configRol: "Configuración de rol",
        configVisaciones: "Config. de visaciones",
        configuraciones: "Configuraciones",
        confirmePassword: "Confirme contraseña",
        consecuencia: "Consecuencia",
        consecuencias: "Consecuencias",
        contar: "Contar",
        controlActividades: "Control de actividades",
        controlAreaEmpresa: "Control de áreas de empresas",
        controlAreas: "Control de áreas",
        controlBibliotecas: "Control de bibliotecas",
        controlCanalEnvio: "Control de Canales de envío",
        controlCanalRecepcion: "Control de Canales de recepcion",
        controlCargos: "Control de Cargos",
        controlDelitos: "Control de Delitos",
        controlMaterias: "Control de Materias",
        controlCausas: "Control de causas",
        controlClasificacionesDocumentos: "Control de Clasificaciones de documento",
        controlConsecuencias: "Control de consecuencias",
        controlEmpresas: "Control de Empresas",
        controlEstadoDoc: "Control estados de documento",
        controlEstadoEvRiesgo: "Control de estados de eventos",
        controlEstadoPlanAccion: "Control de estados plan de acción",
        controlEstadoOficio: "Control estados de oficio",
        controlFeriados: "Control de Feriados",
        controlFormatoActivos: "Control formatos de activos",
        controlGeneral: "Control general",
        controlGerencias: "Control de Gerencias",
        controlIdTipoCausa: "Control de Identificadores de tipo de causa",
        controlNotificaciones: "Control de notificaciones",
        controlOficinas: "Control de Oficinas",
        controlOrganismosEmisores: "Control de Organismos emisores",
        controlPerfilesAvisos: "Control de perfiles de avisos",
        controlProcesoNivel0: "Control de proceso de ciclos",
        controlProcesoNivel1: "Control de proceso de nivel 1",
        controlProcesoNivel2: "Control de proceso de nivel 2",
        controlProcesoNivel3: "Control de proceso de nivel 3",
        controlProcesoNivel4: "Control de proceso de nivel 4",
        controlRegiones: "Control de regiones",
        controlRelaciones: "Control de Relaciones",
        controlRoles: "Control de roles",
        controlTipoActivos: "Control tipos de activos",
        controlTipoAtrasos: "Control tipos de atrasos",
        controlTipoRiesgos: "Control de Tipos de riesgos",
        controlTiposDocumento: "Control de Tipos de documento",
        controlTiposRequerimiento: "Control de Tipos de requerimiento",
        controlTiposOficio: "Control de Tipos de oficio",
        controlUsuarios: "Control de usuarios",
        controlUbicacionActivos: "Control ubicación de activos",
        controlPlantillas: "Control de plantillas",
        correoYevidencia: "Correo y evidencia",
        creacion: "Creación",
        crearExitoso: "Operación realizada con éxito. Información guardada",
        criticidad: "Criticidad",
        criticidadActual: "Criticidad actual",
        criticidadInicial: "Criticidad inicial",
        criteriosMitigacion: "Criterios de mitigación",
        cuentaAsociada: "Cuenta asociada",
        dashboard: "Dashboard",
        de: "de",
        deCada: "de cada",
        debeSeleccionarCompProcCrearAct: "Debe seleccionar un compromiso o proceso para crear una actividad",
        debeSeleccionarCompProcCrearRiesgo: "Debe seleccionar un compromiso o proceso para crear un riesgo",
        dependeDe: "Depende de",
        dependencia: "Dependencia",
        desactivado: "Desactivado",
        desactivarEnvioCorreos: "Desactivar envío de correos",
        desaprobarResidual: "Desaprobar residual",
        descargarArchivo: "Descargar archivo",
        descargarError: "Error al generar archivo",
        descargarExitoso: "Archivo generado con éxito",
        descargarMapa: "Descargar mapa",
        descargar: "Descargar",
        descargarTodo: "Descargar todo",
        descripcion: "Descripción",
        descripcionAct: "Descripción de actividad",
        descripcionCont: "Descripción del control",
        descripcionPlanAccion: "Descripción del plan de acción",
        descripcionActPlaceholder: "Ingrese una descripción para la actividad",
        descripcionContPlaceholder: "Ingrese una descripción para el control",
        descripcionPlanAccionPlaceholder: "Ingrese una descripción para el plan de acción",
        descripcionUobjetivo: "Descripción u objetivo",
        desdeFechaVenc: "Desde (Fecha vencimiento)",
        detalle: "Detalle",
        detalleActividad: "Detalle de actividad",
        detalleDeDoc: "Detalle de documento",
        detalleFormula: "Detalle de formula",
        detalleIndicador: "Detalle de indicador",
        detalleProceso: "Detalle de proceso",
        detalleRepeticion: "Detalle repetición",
        detalles: "Detalles",
        dia: "Día",
        diario: "Diario",
        dias: "días",
        diasAntesFechaVencHabAct: "Días antes de la fecha de vencimiento se habilitará la actividad",
        diasAtraso: "Días Atraso",
        diasAvisoPrevio: "Días aviso previo",
        diasCierre: "Días de cierre",
        diasCorridos: "Días corridos",
        diasHabiles: "Días hábiles",
        disponibilidad: "Disponibilidad",
        docAsociado: "Documento asociado",
        docPadre: "Documento padre",
        documento: "Documento",
        documentos: "Documentos",
        duenoProc: "Dueño proc.",
        duenoProceso: "Dueño de proceso",
        enviar: "Enviar",
        editar: "Editar",
        editarActividad: "Editar Actividad",
        editarActivo: "Editar activo",
        editarCompromiso: "Editar compromiso",
        editarDoc: "Editar documento",
        editarExitoso: "Operación realizada con éxito. Información actualizada",
        editarIndicador: "Editar indicador",
        editarProceso: "Editar proceso",
        editarRelacion: "Editar relación",
        editarRiesgo: "Editar riesgo",
        efectividad: "Efectividad",
        efectividadControl: "Efectividad control",
        ejecutar: "Ejecutar",
        ejecutor: "Ejecutor",
        ejecutorActual: "Ejecutor actual",
        eliminar: "Eliminar",
        el: "El",
        elDia: "El día",
        elUltimoDiaDe: "El último día de",
        elUltimoDiaDeCada: "El último día de cada",
        elementosAimportar: "Elementos a importar",
        email: "Email",
        empresa: "Empresa",
        empresaYusuarios: "Empresa y usuarios",
        empresas: "Empresas",
        en: "En",
        enCurso: "En curso",
        enCursoAtrasadas: "En curso atrasadas",
        enEjecucion: "En ejecución",
        enEspera: "En espera",
        enviarCorreoCita: "Enviar correo de cita",
        enviarResumenAlAdmin: "Envía resumen a administrador",
        enviarResumenAlAdminDatos: "Envía resumen al administrador de datos",
        errorFaltaArchivoExcel: "Debe ingresar el archivo excel para continuar.",
        errorEmpresa: "Se debe seleccionar una empresa para continuar",
        errorConectarSharepoint: "Error al intentar conectar con SharePoint",
        errorDesconocido: "Error desconocido",
        errorPeso: "La evidencia a adjuntar debe ser de un peso menor o igual a: ",
        errorIngresarExcel: "Debe ingresar el archivo excel para continuar.",
        errores: "Errores: ",
        esBinario: "Es binario",
        esInformativa: "¿Es una actividad informativa?",
        esperaVisacion: "Espera de visación",
        estableceDiasConsideradoVenc: "Establece los días que son considerados para el vencimiento",
        establecePtjeMitigacionConsecuencia: "Variable que establece el porcentaje de mitigación de probabilidad del riesgo con esta actividad de control",
        establecePtjeMitigacionProbabilidad: "Variable que establece el porcentaje de mitigación de impacto del riesgo con esta actividad de control",
        estableceSiActividadEjecutaEnHabil: "Establece si la actividad se ejecutará en días habiles o no",
        estableceSiDebeMostrarCuadroAlSeleccCanal: "Establece si se debe desplegar un cuadro de texto al seleccionar este canal",
        estableceSiFeriadoRepite: "Establece si el feriado se repetirá cada año o es un feriado puntual",
        estadisticas: "Estadísticas",
        estado: "Estado",
        estadoEventosRiesgos: "Estado de evento de riesgo",
        estadosEventosRiesgos: "Estados de evento de riesgo",
        estadosPlanAccion: "Estados de plan de acción",
        estadoAprobacion: "Estado aprob.",
        estadoDoc: "Estado documento",
        estadoEjecucion: "Estado ejecución",
        estadoEntrega: "Estado de entrega",
        estadoRevision: "Estado revisión",
        estadoVisacion: "Estado visación",
        estadosDocumento: "Estados documento",
        etiqueta: "Etiqueta",
        eventual: "Eventual",
        evidencia: "Evidencia",
        evidenciaBorrada: "Evidencia borrada",
        exigeDetalle: "Exige detalle",
        exigeEvidenciaCerrarActividad: "Se exige evidencia al cerrar actividades",
        exigeEvidenicaFormaPredeterminada: "Se exige evidencia de forma predeterminada",
        exportarCsv: "Exportar CSV",
        exportarExcel: "Exportar Excel",
        exportarPdf: "Exportar PDF",
        fecha: "Fecha",
        fechaAl: "Al",
        fechaCarga: "Fecha de carga",
        fechaCierre: "Fecha cierre",
        fechaDesde: "Desde",
        fechaHasta: "Hasta",
        fechaEjecucion: "Fecha ejecución",
        fechaIngreso: "Fecha ingreso",
        fechaIngresoAlSistema: "Fecha ingreso al sistema",
        fechaPublicacion: "Fecha publicación",
        fechaRecepcionEmpresa: "Fecha recepción empresa",
        fechaRevision: "Fecha revisión",
        fechaPlazoRespuesta: "Fecha plazo de respuesta",
        plazoRespuestaInterno: "Plazo de respuesta interno",
        fechaPlazoInterno: "Plazo interno",
        fechaEmision: "Fecha emisión oficio",
        fechaVenc: "Fecha de vencimiento",
        fechaVisacion: "Fecha visación",
        feriados: "Feriados",
        filtrar: "Filtrar",
        filtrarPor: "Filtrar por",
        filtrarPorFecha: "Filtrar por fechas",
        filtros: "Filtros",
        footerCorreoEjecutor: "Footer correo ejecutor",
        footerCorreoVisador: "Footer correo visador",
        formula: "Formula",
        frecControl: "Frecuencia de control",
        frecuencia: "Frecuencia",
        generaExcelConsolRiesgosProcCtr: "Genera un Excel consolidado de riesgos, procesos y controles.",
        general: "General",
        generales: "Generales",
        gerencia: "Gerencia",
        gerencias: "Gerencias",
        gerente: "Gerente",
        gestorActControl: "Gestor de controles",
        gestorActividades: "Gestor de actividades",
        gestorPlanesAccion: "Gestor planes de acción",
        gestorActivosInformacion: "Gestor de activos de información",
        gestorCompromisosYprocesos: "Gestor de compromisos y procesos",
        gestorCompromisos: "Gestor de compromisos",
        gestorDocumentos: "Gestor de documentos",
        gestorIndicadores: "Gestor de indicadores",
        gestorRiesgos: "Gestor de riesgos",
        gestorMisRiesgos: "Gestor mis riesgos",
        gestorEventoRiesgos: "Gestor de eventos de riesgo",
        gestorProyectos: "Gestor de proyectos",
        gestorProcesos: "Gestor de procesos",
        gestorCodigosOficios: "Gestor de códigos oficios",
        guardar: "Guardar",
        guardarActividad: "Guardar actividad",
        guardarActivo: "Guardar activo",
        guardarCambios: "Guardar cambios",
        guardarDoc: "Guardar documento",
        guardarIndicador: "Guardar indicador",
        guardarRiesgo: "Guardar riesgo",
        hastaFechaVenc: "Hasta (Fecha vencimiento)",
        historial: "Historial",
        historialRepeticiones: "Historial de repeticiones",
        hora: "Hora",
        hoy: "Hoy",
        identificacion: "Identificación",
        identificadorCausa: "Identificador causa",
        identificadoresCausas: "Identificadores de causas",
        idioma: "Idioma",
        igualA: "Igual a",
        importacion: "Importación",
        importador: "Importador",
        importar: "Importar",
        importarDocumentosYcompromisos: "Importar documentos y compromisos",
        importacionMasiva: "Importación masiva",
        importacionMasivaProcesoRiesgos: "Importación masiva de procesos y riesgos",
        importacionMasivaProcesos: "Importación masiva de procesos",
        importacionMasivaRiesgos: "Importación masiva de riesgos",
        importacionMasivaIndicadores: "Importación masiva de indicadores",
        importacionMasivaEventosRiesgo: "Importación masiva eventos de riesgo",
        importacionMasivaPlanesAccion: "Importación masiva planes de acción",
        importacionMasivaOrganigrama : "Importación masiva de organigrama",
        importacionMasivaCumplimiento: "Importación masiva de cumplimiento",
        importarExitoso: "El sistema importó exitosamente los elementos",
        incluirDetalle: "Incluir detalle",
        incluirEncabezado: "Incluir encabezado",
        indicador: "Indicador",
        indicadoresPorRevisar: "Indicadores por revisar",
        indiceCons: "Índice Cons.",
        indiceConsecuencia: "Índice impacto",
        indiceProb: "Índice Prob.",
        indiceProbabilidad: "Índice probabilidad",
        informacion: "Información",
        informacionGral: "Información general",
        informativa: "Informativa",
        informativas: "Informativas",
        informativo: "Informativo",
        ingreseCadenaConexion: "Ingrese cadena de conexión",
        ingreseCarpeta: "Ingrese carpeta",
        ingreseComentarioConsecuencia: "Ingrese un comentario para la consecuencia...",
        ingreseComentarioParaControl: "Ingrese un comentario para el control...",
        ingreseCorreo: "Ingrese correo",
        ingreseDescripDoc: "Ingrese una descripción para el documento",
        ingreseDescripIdentificador: "Ingrese una descripción para el identificador",
        ingreseDescripTipoActivo: "Ingrese una descripción para el tipo de activo",
        ingreseDescripTipoDoc: "Ingrese una descripción para el tipo de documento",
        ingreseDescripTipoReq: "Ingrese una descripción para el tipo de requerimiento",
        ingreseDescripTipoRiesgo: "Ingrese una descripción para el tipo de riesgo",
        ingreseDescripUobjetivoCompromiso: "Ingrese una descripción u objetivo para el compromiso",
        ingreseObjetivoProceso: "Ingrese un objetivo para el proceso",
        ingreseDescripcionActivo: "Ingrese una descripción para el activo",
        ingreseDetalleIndicador: "Ingrese detalle de indicador",
        ingreseDiasVisacion: "Ingrese días de visación",
        ingreseEmpresa: "Ingrese empresa",
        ingreseEtiquetaGerencia: "Ingrese etiqueta gerencia",
        ingreseEtiquetaGerente: "Ingrese etiqueta gerente",
        ingreseEtiquetaResponsable: "Ingrese etiqueta responsable",
        ingreseHostCorreo: "Ingrese host de correo",
        ingreseLaFormula: "Ingrese la formula, Ej. a+b",
        ingreseLaMedida: "Ingrese la medida, Ej. % o $",
        ingreseLaTolerancia: "Ingrese la tolerancia",
        ingreseNombre: "Ingrese nombre",
        ingreseNombreDespliegue: "Ingrese nombre despliegue",
        ingreseObjetivoIndicador: "Ingrese objetivo de indicador",
        ingresePassword: "Ingrese contraseña",
        ingresePuerto: "Ingrese puerto",
        ingreseRutaCarpeta: "Ingrese ruta de carpeta",
        ingreseUrlSitio: "Ingrese URL del sitio",
        ingreseValor: "Ingrese valor",
        integridad: "Integridad",
        jueves: "Jueves",
        laEvidenciaEsObligatoria: "¿La evidencia es obligatoria?",
        lector: "Lector",
        lectores: "Lectores",
        listaAreaEmpresas: "Lista de áreas de empresas",
        listaAreas: "Lista de áreas",
        listaCanales: "Lista de canales",
        listaCargos: "Lista de cargos",
        listaDelitos: "Lista de delitos",
        listaMaterias: "Lista de materias",
        listaCiclos: "Lista de ciclos",
        listaClasificaciones: "Lista de clasificaciones",
        listaConsecuencias: "Lista de consecuencias",
        listaEmpresas: "Lista de empresas",
        listaEstadoDoc: "Lista de estados de documento",
        listaEstadoOficio: "Lista de estados de oficio",
        listaEstadoEvRiesgo: "Lista de estados de eventos de riesgo",
        listaEstadoPlanAccion: "Lista de estados de plan de acción",
        listaFeriados: "Lista de feriados",
        listaFormatoActivos:"Lista de formatos de activo",
        listaGerencias: "Lista de gerencias",
        listaIdentificadores: "Lista de Identificadores",
        listaOficinas: "Lista de oficinas",
        listaOrganismosEmisores: "Lista de organismos emisores",
        listaPerfilesEnvioCorreos: "Lista perfiles envío de correos",
        listaProcesos: "Lista de procesos",
        listaRegiones: "Lista de regiones",
        listaRelacionesEmpresaGerenciaAreaUsuario: "Lista de relaciones Empresa/Gerencia/Área/Usuarios",
        listaRiesgos: "Lista de riesgos",
        listaRoles: "Lista de roles",
        listaTipoActivos: "Lista de tipos de activo",
        listaTipoAtrasos: "Lista tipo de atrasos",
        listaTipoRiesgos: "Lista de tipos de riesgos",
        listaSubTipoRiesgos: "Lista de sub-tipos de riesgos",
        listaTiposDocumento: "Lista de tipos de documento",
        listaTiposRequerimientos: "Lista de tipos requerimientos",
        listaTiposOficio: "Lista de tipos de oficio",
        listaUsuarios: "Lista de usuarios",
        listaUbicacionActivos: "Lista de ubicaciones de activo",
        listaValoresRiesgoControles: "Lista de valores riesgo controles",
        listaValoresRiesgoInherente: "Lista de valores riesgo inherente",
        listaPlantillas: "Lista de plantillas",
        lunes: "Lunes",
        mapaDeCalor: "Mapa de calor",
        martes: "Martes",
        matrizRiesgos: "Matriz de riesgos",
        matrizValorizacion: "Matriz de valorización",
        maximo: "Máximo",
        maximoCargaArchivo: "Tamaño máximo de carga",
        medida: "Medida",
        mensual: "Mensual",
        mes: "Mes",
        meses: "meses",
        miercoles: "Miércoles",
        minimo: "Mínimo",
        misAct: "Mis actividades",
        misActAtrasadasPorAprobar: "Mis act. atrasadas por aprobar",
        misActAtrasadasPorEjecutar: "Mis act. atrasadas por ejecutar",
        misActEnCurso: "Mis act. en curso",
        misActPorAprobar: "Mis act. por aprobar",
        misActividades: "Mis Actividades",
        misIndicadores: "Mis indicadores",
        mitigacionCriticidad: "Mitigación criticidad",
        modoPosicionamientoRiesgo: "Modo de posicionamiento de riesgo",
        mostrar: "Mostrar",
        motivo: "Motivo",
        motivoAtraso: "Motivo de atraso",
        motivosAtrasos: "Motivos de atrasos",
        motivoSolicitud: "Motivo solicitud",
        nadaPorRevisar: "Nada por revisar",
        nadaPorVisar: "Nada por visar",
        nivel: "Nivel",
        nivel1: "Nivel 1",
        nivel2: "Nivel 2",
        nivel3: "Nivel 3",
        nivel4: "Nivel 4",
        nivelTolerancia: "Nivel de tolerancia",
        niveles: "Niveles",
        nivelesDeProcesos: "Niveles de procesos",
        no: "No",
        noEncontrado: "No encontrado.",
        noEspecificado: "No especificado",
        noVigente: "No vigente",
        noVistoPorNadie: "Aún no ha sido visto por nadie",
        nombre: "Nombre",
        nombreArea: "Nombre de área",
        nombreCanal: "Nombre canal",
        nombreCanalDetalle: "Nombre canal detalle",
        nombreCanalEnvio: "Nombre canal envío",
        nombreCanalRecepcion: "Nombre canal recepción",
        nombreCargo: "Nombre cargo",
        nombreCiclo: "Nombre de ciclo",
        nombreCompleto: "Nombre completo",
        nombreCompromiso: "Nombre compromiso",
        nombreDoc: "Nombre documento",
        nombreEmpresa: "Nombre empresa",
        nombreFeriado: "Nombre feriado",
        nombreGerencia: "Nombre gerencia",
        nombreGralProceso: "Nombre general del proceso",
        nombreIdentificador: "Nombre identificador",
        nombreIndicador: "Nombre de indicador",
        nombreOficina: "Nombre oficina",
        nombreOrganismo: "Nombre organismo",
        nombrePerfil: "Nombre de perfil",
        nombreProceso: "Nombre proceso",
        nombreRegion: "Nombre región",
        nombreRol: "Nombre de rol",
        nombreTipoDoc: "Nombre tipo doc.",
        nombreTipoReq: "Nombre tipo req.",
        nombreTipoRiesgo: "Nombre de tipo riesgo",
        nombreSubTipoRiesgo: "Nombre de sub-tipo riesgo",
        nombreUsuario: "Nombre de usuario",
        nombreYapellido: "Ingrese nombre y apellidos",
        nro: "N°",
        nroCausa: "N° causa",
        nroRep: "N° Rep.",
        nuevaActividad: "Nueva Actividad",
        nuevaArea: "Nueva área",
        nuevaAreaEmpresa: "Nueva área empresa",
        nuevaClasificacion: "Nueva clasificación",
        nuevaConsecuencia: "Nueva consecuencia",
        nuevaEmpresa: "Nueva empresa",
        nuevaGerencia: "Nueva gerencia",
        nuevaOficina: "Nueva oficina",
        nuevaFechaPlazo: "Nueva fecha de plazo",
        nuevaRegion: "Nueva región",
        nuevaRelacion: "Nueva relación",
        nuevoActivo: "Nuevo activo",
        nuevoCanal: "Nuevo canal",
        nuevoCargo: "Nuevo cargo",
        nuevoDelito: "Nuevo delito",
        nuevaMateria: "Nueva materia",
        nuevoCiclo: "Nuevo ciclo",
        nuevoCompromiso: "Nuevo compromiso",
        nuevoDoc: "Nuevo documento",
        nuevoEstadoDoc: "Nuevo estado de documento",
        nuevoEstadoOficio: "Nuevo estado de oficio",
        nuevoEstadoEvRiesgo: "Nuevo estado de evento",
        nuevoEstadoPlanAccion: "Nuevo estado de plan de acción",
        nuevoFeriado: "Nuevo Feriado",
        nuevoFormatoActivo: "Nuevo formato de activo",
        nuevoIdentificador: "Nuevo identificador",
        nuevoIndicador: "Nuevo indicador",
        nuevoOrganismoEmisor: "Nuevo organismo emisor",
        nuevoPerfil: "Nuevo perfil",
        nuevoProceso: "Nuevo proceso",
        nuevoRiesgo: "Nuevo riesgo",
        nuevoRol: "Nuevo rol",
        nuevoTipoActivo: "Nuevo tipo de activo",
        nuevoUbicacionActivo: "Nueva ubicación de activo",
        nuevoTipoAtraso: "Nuevo tipo de atraso",
        nuevoTipoDocumento: "Nuevo tipo de documento",
        nuevoTipoReq: "Nuevo tipo req.",
        nuevoTipoRiesgo: "Nuevo tipo de riesgo",
        nuevoSubTipoRiesgo: "Nuevo sub-tipo de riesgo",
        nuevoTipoOficio: "Nuevo tipo de oficio",
        nuevoUsuario: "Nuevo usuario",
        objetivo: "Objetivo",
        objetivoIndicador: "Objetivo del indicador",
        oficina: "Oficina",
        oficinas: "Oficinas",
        opciones: "Opciones",
        oportunidad: "Oportunidad",
        oportunidadControl: "Oportunidad de control",
        orden: "Orden",
        orgEmisor: "Org. emisor",
        organismoEmisor: "Organismo emisor",
        organismosEmisores: "Organismos emisores",
        pendiente: "Pendiente",
        pendientesAprob: "Pendientes aprobación",
        pendientesEjecucion: "Pendientes ejecución",
        perfil: "Perfil",
        perfilAviso: "Perfil de aviso",
        perfiles: "Perfiles",
        perfilesEnvioCorreos: "Perfiles envío de correos",
        periodicidad: "Periodicidad",
        periodico: "Periódico",
        permanente: "Permanente",
        permisos: "Permisos",
        plazoVisadorTexto: "Plazo visador texto",
        porAprobar: "Por aprobar",
        porRevisar: "Por revisar",
        porVencer: "Por vencer",
        porVisar: "Por visar",
        porcentajeMitigacionConsecuencia: "% Mitigación impacto",
        porcentajeMitigacionProbabilidad: "% Mitigación probabilidad",
        prefijoEmail: "Prefijo email",
        primer: "primer",
        primerVenc: "Primer vencimiento",
        probabilidad: "Probabilidad",
        procAsociado: "Proceso asociado",
        proceso: "Proceso",
        procesoNivel1: "Proceso de nivel 1",
        procesoNivel2: "Proceso de nivel 2",
        procesoNivel3: "Proceso de nivel 3",
        procesos: "Procesos",
        procesosNivel1: "Procesos nivel 1",
        procesosNivel2: "Procesos nivel 2",
        procesosNivel3: "Procesos nivel 3",
        procesosNivel4: "Procesos nivel 4",
        proxVenc: "Próx. venc.",
        proxVencimiento: "Próx. vencimiento",
        proxVencimientos: "Próximos vencimientos",
        puntual: "Puntual",
        rangos: "Rangos",
        rebajaMasiva: "Rebaja masiva",
        rebajaMasivaOficios : "Rebaja masiva de oficios",
        realizarImportacion: "Realizar importación",
        reasignacionExitosoActividad: "Reasignación exitosa",
        reasignacionMismoUsuario: "No pueden reasignar oficios al mismo usuario",
        reasignacionMasiva: "Reasignación masiva",
        reasignacionMasivaOficios: "Reasignación masiva oficios",
        reasignarA: "Reasignar a",
        reasignarActividades: "Reasignar actividades",
        reasignarOficios: "Reasignar oficios",
        recepcion: "Recepción",
        rechazaYcierraAct: "Rechaza y cierra la actividad",
        rechazaYdevuelveActAlEjecutor: "Rechaza y devuelve la actividad al ejecutor",
        rechazadas: "Rechazadas",
        rechazar: "Devolver a ejecutor",
        rechazarYcerrar: "Rechazar y cerrar",
        recurrente: "Recurrente",
        regionOseccion: "Región/Sección",
        regiones: "Regiones",
        registrarResultado: "Registrar resultado",
        relaciones: "Relaciones",
        repRechazadas: "Repeticiones rechazadas",
        remitente: "Remitente",
        rendimiento: "Rendimiento",
        rendimientoGral: "Rendimiento general",
        repiteHasta: "Repetir hasta",
        representanteLegal: "Representante legal",
        requerimiento: "Requerimiento",
        requiereVisacion: "¿Requiere visación?",
        responsable: "Responsable",
        responsableIndicador: "Responsable del indicador",
        resultado: "Resultado",
        resumen: "Resumen",
        resumenActividadSemanal: "Resumen de actividades semanal",
        resumenSemanal: "Resumen semanal",
        revisar: "Revisar",
        riesgo: "Riesgo",
        riesgoAsociado: "Riesgo asociado",
        riesgoInherente: "Riesgo inherente",
        riesgoResidual: "Riesgo residual",
        riesgoTiempoReal: "Riesgo en tiempo real",
        riesgos: "Riesgos",
        rol: "Rol",
        roles: "Roles",
        rolesUsuario: "Roles de usuario",
        seguroDeseaBorrarEvidencia: "¿Seguro que desea borrar la evidencia seleccionada?",
        seguroDeseaCancelarActividad: "¿Estás seguro de que deseas cancelar la actividad?",
        seguroDeseaCancelarControl: "¿Estás seguro de que deseas cancelar el control?",
        seguroDeseaEliminarCompromiso: "¿Estás seguro de que deseas eliminar el compromiso",
        seguroDeseaEliminarDoc: "¿Estás seguro de que deseas eliminar el documento",
        seguroDeseaEliminarIndicador: "¿Estás seguro de que deseas eliminar el indicador?",
        seguroDeseaEliminarProc: "¿Estás seguro de que deseas eliminar el proceso",
        seguroDeseaEliminarRelacion: "¿Estás seguro de que deseas eliminar la relación?",
        seguroDeseaEliminarRiesgo: "¿Estás seguro de que deseas eliminar el riesgo",
        seguroDeseaEliminarResultado: "¿Estás seguro de que deseas eliminar el resultado?",
        seguroDeseaEliminarEventoRiesgo: "¿Estás seguro de que deseas eliminar el evento de riesgo",
        seleccioneCsvConDocYcomp: "Seleccione CSV con documentos y compromisos",
        semana: "Semana",
        semanaEl: "semana, el",
        semanal: "Semanal",
        sharepoint: "SharePoint",
        si: "Si",
        sigla: "Sigla",
        siguiente: "Siguiente",
        sinDocAsociado: "Sin documento asociado.",
        sinIndicadorInfo: "Sin información para mostrar",
        sinIndicadores: "Sin indicadores",
        sinRiesgoAsociado: "Sin riesgo asociado.",
        sinRiesgosAsociados: "Sin riesgos asociados",
        sinRol: "Sin rol asignado. Contáctese con el administrador.",
        sinTareasPendientes: "Sin tareas pendientes",
        soloDiaHabil: "Solo día hábil",
        tarea: "Tarea",
        tareaCerrada: "Tarea cerrada",
        tareasEnCurso: "Tareas en curso",
        tareasPorVisar: "Tareas por visar",
        textoMostrarUrlTareas: "Texto a mostrar URL de tareas",
        textoMostrarUrlVisador: "Texto a mostrar URL de visador",
        tipo: "Tipo",
        tipoAlmacenamiento: "Tipo de almacenamiento",
        tipoControl: "Tipo de control",
        tipoDoc: "Tipo de documento",
        tipoDocumentos: "Tipo documentos",
        tipoIndicador: "Tipo de indicador",
        tipoRiesgo: "Tipo de riesgo",
        tiposActivos: "Tipos de activos",
        tiposAtrasos: "Tipos de atrasos",
        tiposRequerimientos: "Tipos de requerimientos",
        tiposRiesgos: "Tipos de riesgos",
        todas: "Todas",
        todasEmpresas: "Todas las empresas",
        todo: "Todo",
        todoDiasSemana: "Todos los días de la semana",
        todos: "Todos",
        todosEjecutores: "Todos los ejecutores",
        toleracionAutoAprobRiesgoResidual: "Tolerancia auto-aprob. r. residual",
        tolerancia: "Tolerancia",
        tooltipCalcularFechaVenc: "Calcular vencimiento según frecuencia",
        totAtrasadas: "Total atrasadas",
        ubicacionYarchivos: "Ubicación y archivos",
        ultimo: "último",
        umbral: "Umbral",
        urlExterna: "URL externa",
        usarConfigSistema: "Usar configuración del sistema",
        usarNivelesProcComoNombre: "Usar niveles de proceso como nombre",
        usarSsl: "Usar SSL",
        usuario: "Usuario",
        usuarios: "Usuarios",
        utilizarLoginProvExterno: "Utilizar inicio de sesión con proveedor externo",
        validacionAreaCrearDoc: "Se debe contar con un área para crear el documento",
        validacionComentarioCierreMasivo: "Debe escribir un comentario de cierre",
        validacionCompromisoCrearRiesgo: "Se debe contar con un compromiso/proceso antes de crear el riesgo",
        validacionDocCrearCompromiso: "Se debe contar con un documento antes de crear el compromiso",
        validacionEmpresa: "Se debe contar con una empresa para continuar",
        validacionGerenciaCrearProc: "Se debe contar con una gerencia antes de crear el proceso",
        validacionNivel1CrearProc: "Se debe contar con un nivel 1 antes de crear el proceso",
        validacionOrgEmisorCrearDoc: "Se debe contar con un organismo emisor para crear el documento",
        validacionTipoCrearDoc: "Se debe contar con un tipo para crear el documento",
        validarConsecuenciaCrearActividad: "Se debe seleccionar una consecuencia",
        validarDescripcionCrearActividad: "La actividad debe tener una descripción",
        validarDetalleCrearIndicador: "El indicador debe tener un detalle",
        validarDiasAvisoPrevioCrearActividad: "Días de aviso previo debe ser mayor o igual a cero",
        validarDiasNegativosCrearActividad: "Días no pueden ser negativos",
        validarDifMinMaxMenor2CrearIndicador: "Diferencia entre valores mínimo y máximo de umbrales 1 y 3 debe ser de al menos 2 unidades",
        validarEmpresaGerencia: "Se debe contar con una empresa antes de crear una gerencia",
        validarEmpresaGerenciaCrearActividad: "La actividad debe tener una empresa y gerencia",
        validarEmpresaGerenciaRelacion: "Se debe contar con una empresa>gerencia antes de crear una relación",
        validarEtiquetaReqCrearIndicador: "Las etiquetas son requeridas",
        validarEtiquetasNoIgualesCrearIndicador: "Las etiquetas no deben ser iguales",
        validarExistenciaElementosSeleccionadosImportador: "Uno o más elementos seleccionados ya se encuentran en el sistema",
        validarFormulaCrearIndicador: "El indicador debe tener una formula",
        validarGerenteEjecutorVisadorCrearActividad: "La actividad debe tener un gerente, ejecutor y visador",
        validarEjecutorDuenoProcesoActividad: "La actividad debe tener un dueño de proceso y un ejecutor",
        validarMaxUmbral1CrearIndicador: "Valor máximo de umbral 1 debe ser mayor que valor mínimo",
        validarMaxUmbral3MayorAlMinCrearIndicador: "Valor máximo de umbral 3 debe ser mayor que valor mínimo",
        validarMedidaCrearIndicador: "El indicador debe tener una medida",
        validarMesesNegativosCrearActividad: "Meses no pueden ser negativos",
        validarMitigacionConsecuenciaCrearActividad: "% Mitigación consecuencia debe estar entre 0 y 100",
        validarMitigacionProbabilidadCrearActividad: "% Mitigación probabilidad debe estar entre 0 y 100",
        validarMotivoCancelarActividad: "Debe ingresar un motivo",
        validarNombreCrearIndicador: "El indicador debe tener un nombre",
        validarNombreUsuarioUsuario: "Nombre de usuario no puede contener espacios",
        validarObjetivoCrearIndicador: "El indicador debe tener un objetivo",
        validarPasswordUsuario: "La contraseña debe tener al menos 9 caracteres",
        validarPasswordsUsuario: "Las contraseñas ingresadas no coinciden",
        validarNombreCompletoUsuario: "El nombre debe tener al menos 4 caracteres",
        validarProcesoNivel1Nivel2: "Se debe contar con un proceso de nivel 1 antes de crear un nivel 2",
        validarProcesoNivel2Nivel3: "Se debe contar con un proceso de nivel 2 antes de crear un nivel 3",
        validarProcesoNivel3Nivel4: "Se debe contar con un proceso de nivel 3 antes de crear un nivel 4",
        validarRiesgoCrearActividad: "Debe actualizar riesgo antes de guardar",
        validarRolPerfil: "Se debe seleccionar al menos un rol",
        validarSeleccActivoCrearActividad: "Se debe seleccionar un activo de información",
        validarSeleccRiesgoCrearActividad: "Se debe seleccionar un riesgo",
        validarToleranciaCrearIndicador: "El indicador debe tener un nivel de tolerancia",
        validarUmbralesRegistrarResultadoIndicador: "Resultado de indicador fuera de los umbrales establecidos",
        validarValoresMinMaxUmbral1y3NoIgualesCrearIndicador: "Valores mínimos y máximos de umbrales 1 y 3 no deben ser iguales",
        validarValoresUmbraleaNoIgualesCrearIndicador: "Valores de umbrales no pueden ser iguales",
        validacionEmpresaCrearProyecto: "Se debe contar con una empresa antes de crear un proyecto",
        validacionPlantillaCrearProyecto: "Se debe contar con una plantilla antes de crear un proyecto",
        valor: "Valor",
        valorLimite: "Valor limite",
        valorResidual: "Valor residual",
        valorRiesgo: "Valor riesgo",
        valores: "Valores",
        valorizacion: "Valorización",
        valorizacionActivo: "Valorización de activo",
        valorizacionControles: "Valorización controles",
        valorizacionRiesgoControles: "Valorización riesgo controles",
        valorizacionRiesgoInherente: "Valorización riesgo inherente",
        valorizarRiesgoInherente: "Valorizar riesgo inherente",
        var: "Var.",
        vencDias: "Venc. en días",
        venceHoy: "Vence hoy",
        vencida: "Vencida",
        vencidas: "Vencidas",
        vencimiento: "Vencimiento",
        verComo: "Ver como",
        verMapaCalor: "Ver mapa de calor",
        verRiesgosTiempoReal: "Ver riesgos tiempo real",
        viernes: "Viernes",
        vigente: "Vigente",
        visaLaActividad: "Visa la actividad",
        visacionDeActividad: "Visación de actividad",
        visacionExitosaActividad: "El sistema visó exitosamente la actividad",
        devueltaExitosoActividad: "El sistema devolvió exitosamente la actividad",
        rechazoExitosoActividad: "El sistema rechazó exitosamente la actividad",
        visador: "Visador",
        visar: "Visar",
        visarActividad: "Visar actividad",
        vistaDetallada: "Ver vista detallada",
        vistaGeneral: "Ver vista general",
        vistaPreviaMapaCalor: "Vista previa mapa de calor",
        vistaPreviaIndicador: "Vista previa indicador",
        vistaPreviaNoDisp: "Vista previa no disponible",
        vistas: "Vistas",
        visualizadores: "Visualizadores",
        y: "y",
        evolucionIndicador: "Evolución de indicador",
        exportacion: "Exportación",
        indicadores: "Indicadores",
        buscadorCompromisosProcesos: "Buscador de compromisos/procesos",
        buscadorProcesos: "Buscador de procesos",
        buscar: "Buscar",
        procesoParaCombinacionNoEncontrado: "No existe un proceso para la combinación dada",
        definicion: "Definición",
        responsables: "Responsables",
        umbrales: "Umbrales",
        listaResultados: "Lista de resultados",
        descargarIndicador: "Descargar indicador",
        tipoActividad: "Tipo de actividad",
        deseaSalirPagina: "¿Desea salir del sitio?",
        alertaPerdidaCambios: "Es posible que los cambios realizados no se guarden",
        oficios: "Oficios",
        oficioPadre: "Oficio padre",
        ofHijos: "Oficios hijos",
        tipoOficios: "Tipo oficios",
        estadoOficio: "Estado oficio",
        estadosOficio: "Estados oficio",
        estadoPlanAccion: "Estado plan acción",
        materias: "Materias",
        materia: "Materia",
        relacionUsuarioMateria: "Relación usuario/materia",
        relacionUsuarioTema: "Relación usuario/tema",
        delitos: "Delitos",
        validarUsuarioMateria: "Se debe contar con un tema antes de crear la relación usuario/tema",
        listaRelacionesUsuarioMateria: "Lista de relaciones usuario/tema",
        editarOficio: "Editar oficio",
        nroOficioInterno: "N° oficio interno",
        validacionMateriaCrearOficio: "Se debe contar con un tema para crear el oficio",
        validacionTipoCrearOficio: "Se debe contar con un tipo para crear el oficio",
        nuevoOficio: "Nuevo oficio",
        guardarOficio: "Guardar oficio",
        archivoBorrado: "Archivo borrado",
        seguroDeseaEliminarOficio: "¿Estás seguro de que deseas eliminar el oficio",
        gestorOficios: "Gestor de oficios",
        detalleDeOficio: "Detalle de oficio",
        asignaciones: "Asignaciones",
        oficio: "Oficio",
        oficioAsociado: "Oficio asociado",
        plazos: "Plazos",
        plazoActual: "Plazo actual",
        fechaSolicitud: "Fecha solicitud",
        plazoPropuesto: "Plazo propuesto",
        plazoRespuesta: "Plazo de respuesta",
        aprobar: "Aprobar",
        rechazarSolicitud: "Rechazar",
        detalleSolicitud: "Detalles de la solicitud",
        sinAccionesDisponibles: "Sin acciones disponibles",
        sinOficioRelacionado: "Sin oficio relacionado",
        aprobacionExitosa: "La solicitud se ha aprobado correctamente",
        rechazoExitoso: "La solicitud se ha rechazado correctamente",
        permiteReasignacion: "Permite reasignación",
        hrsParaReasignacion: "Hrs. para reasignación",
        nuevaAsignacion: "Nueva asignación",
        debeSeleccionarOficioCrearAct: "Debe seleccionar un oficio para crear una actividad",
        elaboracionRespuesta: "Elaboración de respuesta",
        verOficio: "Ver oficio",
        verArchivo: "Ver archivo",
        nroCarta: "N° carta",
        areasRequeridas: "Áreas requeridas",
        fechaSolInformacion: "Fecha sol. información",
        fechaRespuestaSolInfo: "Fecha respuesta sol. info.",
        rutsClientesInformados: "Ruts clientes informados",
        delitosAsociados: "Delitos asociados",
        canalEnvio: "Canal envío",
        enElaboracion: "En elaboración",
        seguroDeseaBorrarArchivo: "¿Seguro que desea borrar el archivo seleccionado?",
        detalleRespuesta: "Detalle respuesta",
        historialRespuestas: "Historial de respuestas",
        visacionRespuesta: "Visación de respuesta",
        misOficios: "Mis oficios",
        asignadorOficios: "Asignador de oficios",
        bibliotecaOficios: "Biblioteca de oficios",
        bibliotecaRespOficios: "Biblioteca respuestas Oficios",
        detalleAsignacion: "Detalle de asignación",
        arbolProcesos: "Arbol de procesos",
        relacionarNivel: "Relacionar nivel",
        mostrarCanceladas: "Mostrar canceladas",
        mayorIgualA: "Mayor igual a",
        menorIgualA: "Menor igual a",
        entre: "Entre",
        administrador: "Administrador",
        revisor: "Revisor",
        duenoControl: "Dueño de control",
        rInherente: "R. inherente",
        rResidual: "R. residual",
        activado: "Activo",
        perfilUsuario: "Perfil de usuario",
        configCuenta: "Configuración de cuenta",
        soloLectura: "Solo lectura",
        mostrarSoloEmpresaRel: "Mostrar datos solo de empresas relacionadas",
        inicioSesionProvCancelado: "Inicio de sesión con proveedor cancelado",
        ingreseCredParaContinuar: "Ingrese sus credenciales para continuar",
        seleccioneTenantParaContinuar: "Seleccione un tenant para continuar",
        iniciarSesion: "Iniciar Sesión",
        iniciaConProv: "Inicia sesión con algún proveedor",
        iniciarConMicrosoft: "Iniciar sesión con microsoft",
        iniciarConGoogle: "Iniciar sesión con google",
        solicitante: "Solicitante",
        bajo: "Bajo",
        medio: "Medio",
        alto: "Alto",
        muyAlto: "Muy Alto",
        aTiempo: "A tiempo",
        fueraDePlazo: "Fuera de plazo",
        cancelado: "Cancelado",
        alta: "Alta",
        media: "Media",
        baja: "Baja",
        noPermitido: "¡No permitido!",
        areaRestringida: "Área restringida",
        noTienePermisosSufientesAccesoPagina: "Usted no tiene los permisos suficientes para acceder a esta página. Pongase en contacto con el administrador.",
        sinLectores: "Sin lectores",
        sistema: "Sistema",
        importarResultados: "Importar resultados",
        delimitador: "Delimitador",
        formatoFecha: "Formato de fecha",
        aaaa: "AAAA",
        mm: "MM",
        dd: "DD",
        seleccioneCsvConResultados: "Seleccione CSV con resultados",
        descargarPlantilla: "Descargar plantilla",
        resultadoIndicadorFueraUmbralOsinComentario: "Resultado de indicador fuera de los umbrales establecidos o sin comentario en umbral 3",
        errorEnFila: "Error en fila",
        fechaExisteSobreescribir: "Resultado con fecha ya existe. Se reemplazará el valor",
        nuevoInicioSesion: "Nuevo inicio de sesión",
        registros: "Registros",
        historialEnvioCorreos: "Historial envíos de correos",
        sinNotificaciones: "Sin notificaciones",
        gestorMarcaciones: "Gestor de marcaciones",
        marcaciones: "Marcaciones",
        iniciarTrabajo: "Iniciar trabajo",
        tomarPausa: "Tomar pausa",
        finalizarJornada: "Finalizar jornada",
        cliente: "Cliente",
        proyecto: "Proyecto",
        reporteActivo: "Reporte activo",
        continuarTrabajo: "Continuar trabajo",
        trabajoRealizadoHastaMomento: "Describa el trabajo realizado hasta el momento",
        descripTrabajoRealizado: "Descripción del trabajo realizado",
        trabajoRealizado: "Trabajo realizado",
        validacionEmpresaCrearReporte: "Se debe contar con una empresa antes de crear un reporte",
        validacionProyectoCrearReporte: "Se debe contar con un proyecto antes de crear un reporte",
        codInforme: "Código informe",
        a: "A",
        distribucion: "Distribución",
        cc: "C.C.",
        nombreResponsable: "Nombre responsable",
        cargoResponsable: "Cargo responsable",
        reporteCumplimiento: "Reporte de cumplimiento",
        reporteDocumento: "Reporte de documentos",
        reporteRiesgos: "Reporte de riesgos",
        reporteEventosRiesgos: "Reporte eventos de riesgos",
        reporteIndicadores: "Reporte de indicadores",
        reporteOficios: "Reporte de oficios",
        tipoDocIntrNormNoEstabl: "Tipos de documentos Internos y Normativos no establecidos",
        configReportes: "Config. reportes",
        configArchivos: "Config. archivos",
        tipoDocInterno: "Tipo doc. interno",
        tipoDocNormativo: "Tipo doc. normativo",
        misReportes: "Mis reportes",
        generarReporte: "Generar Reporte",
        urlLogo: "URL de logo",
        horasTrab: "Horas trab.",
        controlClientes: "Control de Clientes",
        nuevoCliente: "Nuevo cliente",
        controlTipoProyectos: "Control de tipos de proyectos",
        nuevoTipoProyecto: "Nuevo tipo proyecto",
        listaClientes: "Lista de clientes",
        listaTiposProyectos: "Lista de tipos de proyecto",
        controlEtapas: "Control de Etapas",
        listaEtapas: "Lista de etapas",
        nuevaEtapa: "Nueva etapa",
        horasDuracion: "Horas duración",
        timeReport: "TimeReport",
        clientes: "Clientes",
        etapas: "Etapas",
        tiposProyecto: "Tipos de proyecto",
        proyectos: "Proyectos",
        controlProyectos: "Control de proyectos",
        listaProyectos: "Lista de proyectos",
        nuevoProyecto: "Nuevo proyecto",
        participantesProyecto: "Participantes de proyecto",
        nuevoReporte: "Nuevo reporte",
        cerrarProyecto: "Cerrar proyecto",
        incluirIndicadores: "Incluir indicadores",
        formatoReporte: "Formato Reporte",
        formatoA: "Formato A",
        formatoB: "Formato B",
        formatoC: "Formato C",
        formatoD: "Formato D",
        formatoE: "Formato E",
        listaRelacionesEmpresaUsuario: "Lista de relaciones empresa/usuario",
        relacionesEmpresaUsuario: "Relaciones empresa/usuario",
        validarEmpresaUsuario: "Se debe contar con una empresa antes de crear la relación empresa/usuario",
        liderProyecto: "Lider de proyecto",
        total: "Total",
        tipoDuracion: "Tipo de duración",
        fechaInicioPrev: "F. inicio",
        fechaTerminoPrev: "F. término prevista",
        hhUtilizadas: "HH Utilizadas",
        MostrarSoloIndicadores: "Mostrar solo indicadores",
        prioridad: "Prioridad",
        lista: "Lista",
        tablero: "Tablero",
        gestorTareasCortas: "Gestor de tareas cortas",
        nuevaTarea: "Nueva tarea",
        titulo: "Título",
        permiteExpiracion: "Permite expiración",
        detalleTarea: "Detalle de tarea",
        misTareas: "Mis Tareas",
        noIniciada: "No iniciadas",
        ejecutadas: "Ejecutadas",
        expiradas: "Expiradas",
        fechaCreacion: "Fecha creación",
        fechaModificacion: "Fecha modificación",
        docHijos: "Documentos hijos",
        justificacionProb: "Justificación de probabilidad",
        justificacionCons: "Justificación de impacto",
        gradoRiesgoInherente: "Grado de riesgo inherente",
        gradoRiesgoResidual: "Grado de riesgo residual",
        gradoRiesgoResidualDiseno: "Grado de riesgo residual (Diseño)",
        gradoRiesgoResidualReal: "Grado de riesgo residual (Real)",
        calificacion: "Calificación",
        mitigacion: "Mitigación",
        ejecucion: "Ejecución",
        reasignar: "Reasignar",
        formatoActivos: "Formatos de activos",
        ubicacionActivos: "Ubicaciones de activo",
        ubicacion: "Ubicación",
        evaluacion: "Evaluación",
        nombrePlantilla: "Nombre de plantilla",
        nuevaPlantilla: "Nueva plantilla",
        auditoria: "Auditoría",
        nroAct: "N° de act.",
        predecesoras: "Predecesoras",
        importarActividades: "Importar actividades",
        agregarActividad: "Agregar actividad",
        seleccioneXlsxImpPlantillaAct: "Seleccione Excel (.xlsx) con lista de actividades a importar a la plantilla",
        fechaInicio: "F. inicio",
        fechaTermino: "F. término",
        avanceProyecto: "Avance del proyecto",
        plantilla: "Plantilla",
        ejecutorResponsable: "Ejecutor responsable",
        visadorResponsable: "Visador responsable",
        equipoProyecto: "Equipo de proyecto",
        notificarResponsables: "Enviar notificación a responsables (ejector y visador)",
        notificarEquipoProyecto: "Enviar notificación a equipo de proyecto",
        iniciarProyecto: "Iniciar proyecto",
        completarActividad: "Completar actividad",
        soloCompromisos: "Solo compromisos",
        soloProcesos: "Solo procesos",
        documentado: "¿Documentado?",
        afectaCons: "¿Afecta Impacto?",
        afectaProb: "¿Afecta Probabilidad?",
        sinDefinir: "Sin definir",
        interno: "Interno",
        externo: "Externo",
        internoYexterno: "Interno y externo",
        origen: "Origen",
        reasignaciones: "Reasignaciones",
        cargando: "Cargando...",
        formato: "Formato",
        duenoActivo: "Dueño de activo",
        calcularProb: "Calcular probabilidad",
        calcularCons: "Calcular impacto",
        seleccionar: "Seleccionar",
        calBaseGrupoCriterio: "Calculado en base a grupo de criterios",
        sinCriteriosConfigurados: "Sin criterios configurados",
        creaActCumplComprDoc: "Crea una actividad para cumplir con algún compromiso y/o documento",
        creaCtrlMitRiesgo: "Crea un control para mitigar un riesgo",
        creaActPlanAccAsocRiesgo: "Crea una actividad de plan de acción asociada a un riesgo",
        validarCompromisoCrearActividad: "La actividad debe estar asociada a un compromiso",
        generandoArchivo: "Generando archivo",
        incluirActComoLectorDueno: "Incluir actividades con rol de lector o dueño de control",
        horarios: "Horarios",
        controlHorarios: "Control de horarios",
        listaHorarios: "Lista de horarios",
        nuevoHorario: "Nuevo horarios",
        turnos: "Turnos",
        diaInicio: "Día inicio",
        diaTermino: "Día término",
        horaInicio: "Hora inicio",
        horaTermino: "Hora término",
        agregarRolPerfil: "Agregar rol a perfil",
        visadorActual: "Visador actual",
        gantt: "Gantt",
        mostrarVistaGantt: "Mostrar vista Gantt",
        reanudar: "Reanudar",
        incluirJustificacion: "Incluir Justificación",
        incluirRiesgosNoUtil: "Incluir riesgos no aplican",
        tipoGestionInterna: "Tipo de gestión interna",
        tema: "Tema",
        temas: "Temas",
        controlTemas: "Control de temas",
        listaTemas: "Lista de temas",
        nuevoTema: "Nuevo tema",
        nuevo: "Nuevo",
        nuevoControl: "Nuevo control",
        nuevoPlan: "Nuevo plan",
        control: "Control",
        requiereEjecutor: "¿Requiere ejecutor?",
        codInterno: "Código interno",
        cumplimiento: "Cumplimiento",
        riesgosEindicadores: "Riesgos e indicadores",
        ofiEnCurso: "Oficios en curso",
        ofiTotSiendoCursados: "Oficios totales siendo cursados",
        ofiTotEsperaAprob: "Oficios totales a la espera de aprobación",
        ofiTotEnEstadoEjecAtrasados: "Oficios totales en estado de ejecución atrasados",
        ofiTotProcVisacionAtrasados: "Oficios totales en proceso de visación atrasados",
        ofiAtiempo: "Oficios a tiempo",
        ofiAtrasados: "Oficios atrasados",
        ofiSiendoCursados: "Oficios siendo cursados",
        ofiAunNoHanSidoCerradas: "Oficios que aún no han sido cerrados",
        ofiEntregaFormaAtrasada: "Oficios entregados con retraso",
        ofiEsperaSerVisados: "Oficios en espera de ser visados",
        ofiQueSeEncuentranAtrasados: "Oficios que se encuentran atrasados",
        ofiAtrasadasPorVisar: "Oficios atrasados por visar",
        ofiTotRechazados: "Oficios totales rechazados",
        ofiVisadosAtiempo: "Oficios visados a tiempo",
        ofiVisadosConRetraso: "Oficios visados con retraso",
        descripcionMateria: "Descripción materia",
        descripcionMateriaPlaceholder: "Ingrese una descripción para la materia",
        controles: "Controles",
        soloRiesgosPlanAccion: "Solo riesgos con planes de acción",
        reporteControles: "Reporte de controles",
        reportePlanesAccion: "Reporte de planes de acción",
        reqRespuesta: "Requiere respuesta",
        reqGestion: "Requiere gestión",
        duenoPolitica: "Dueño de política",
        plantillaRiesgos: "Plantilla de riesgos",
        agregarRiesgos: "Agregar riesgos",
        planAccion: "Plan de acción",
        planesAccion: "Planes de acción",
        filtrarPorInherente: "¿Filtrar por valor inherente?",
        filtrarPorResidual: "¿Filtrar por valor residual?",
        impacto: "Impacto",
        generarExcel: "Generar excel",
        procesoCritico: "Proceso crítico",
        configEvProcesosCriticos: "Config. evaluación procesos críticos",
        riesgoResidualMayorA: "Riesgo residual mayor a",
        riesgoInherenteMayorA: "Riesgo inherente mayor a",
        controlMayorA: "Control mayor a",
        reglasAplicadas: "Reglas aplicadas",
        o: "O",
        subTiposRiesgos: "Sub-tipos de riesgos",
        detalleControl: "Detalle de control",
        requiereEjecucion: "¿Requiere ejecución?",
        configEvRiesgosCriticos: "Config. evaluación riesgos críticos",
        riesgoCritico: "Riesgo crítico",
        crearPlanAccion: "Crear plan de acción",
        indicadorCritico: "Indicador crítico",
        indicadorNormal: "Indicador normal",
        configEvIndicadoresCriticos: "Config. evaluación indicadores críticos",
        indicadorRojoMayorIgualA: "Indicador rojo mayor igual a",
        indicadorAmarilloMayorIgualA: "Indicador amarillo mayor igual a",
        oSi: "O si",
        agregarProceso: "Agregar proceso",
        sinProcesosAsociados: "Sin procesos asociados",
        quitarProceso: "Quitar proceso",
        seguroDeseaQuitarProcesoRiesgo: "¿Está seguro de que desea eliminar la evaluación del proceso sobre el riesgo?",
        valorInherente: "Valor inherente",
        calificacionProb: "Calificación Prob.",
        calificacionCons: "Calificación Cons.",
        procesoYaRegistrado: "Proceso ya se encuentra registrado en la lista",
        porRebajar: "Por rebajar",
        rebajador: "Rebajador",
        estadoRebaja: "Estado rebaja",
        fechaRebaja: "Fecha rebaja",
        oficiosEnCurso: "Oficios en curso",
        oficiosPorVisar: "Oficios por visar",
        oficiosPorRebajar: "Oficios por rebajar",
        prorroga: "Prórroga",
        solicitudProrroga: "Solicitud de prórroga",
        solicitudesProrroga: "Solicitudes de prórroga",
        actInactivaSolicitudProrroga: "La asignación se habilitará cuando la solicitud de prórroga sea evaluada por visador",
        solicitudProrrogaExitosa: "Solicitud de prórroga enviada",
        nadaPorRebajar: "Nada por rebajar",
        rebajaExitosa: "El sistema rebajó exitosamente el oficio",
        rebajaRespuesta: "Rebaja de respuesta",
        rebajar: "Rebajar",
        eventoRiesgo: "Evento de riesgo",
        nuevoEventoRiesgo: "Nuevo evento de riesgo",
        editarEventoRiesgo: "Editar evento de riesgo",
        afectacion: "Afectación",
        detalleEvento: "Detalle del evento",
        nroAfectados: "N° de afectados",
        montoProvision: "Monto provisión",
        montoPerdida: "Monto perdida",
        informacionReporte: "Información reporte",
        fechaOcurrencia: "Fecha ocurrencia",
        fechaDeteccion: "Fecha detección",
        fechaSolucion: "Fecha solución",
        reportador: "Reportador",
        areasAfectadas: "Áreas afectadas",
        nuevoEvento: "Nuevo evento",
        areaReportador: "Área usuario que reporta",
        riesgoMaterializado: "Riesgo materializado",
        validarProcesoCrearControl: "Control debe estar asociada a un proceso",
        validarProcesoCrearPlanAccion: "Plan acción debe estar asociada a un proceso",
        plantillaControles: "Plantilla de controles",
        listaControles: "Lista de controles",
        relacionar: "Relacionar",
        relacionControlProcesoRiesgo: "Relacionar controles/procesos/riesgos",
        depPlantilla: "Dependiente de plantilla",
        seguroDeseaGuardarControlRelacion: "Control se encuentra relacionado a una plantilla, si lo edita se perderá la relación. ¿Estás seguro de que deseas guardar cambios?",
        actividadesControles: "Actividades/Controles",
        listaOficios: "Lista de oficios",
        listaAsigOficios: "Lista de asignación oficios",
        requiereResponsableGrc: "¿Requiere responsable en GRC?",
        requiereEjecucionGrc: "¿Requiere ejecución en GRC?",
        totalElementos: "Total de elementos",
        descargarDesde: "Descargar desde",
        hasta: "Hasta",
        validacionMaxRangosExportador: "Limite máximo de descarga es de 5000 elementos",
        validacionRangosExportador: "Rango 'desde' y 'hasta' debe ser mayor a cero",
        tenant: "Tenant",
        asignacionMasivaPorTema: "Asignación masiva por tema",
        asignacionExitosa: "Asignación exitosa",
        tipoGestion: "Tipo gestión",
        usarPlazoRespOficios: "Usar plazo de respuesta de cada oficio",
        ingresosDiariosUltimos30Dias: "Ingresos diarios últimos 30 días",
        nroCodigos: "Cant. de códigos",
        generarCodigos: "Generar códigos",
        nuevaSolicitud: "Nueva solicitud",
        id: "Id",
        oficiosCargados: "Oficios cargados",
        oficiosAsignados: "Oficios asignados",
        ofiTotCargadosGrc: "Oficios totales creados en GRC",
        ofiTotAsignadosGrc: "Oficios totales asignados en GRC",
        correlativoEnviadoEmailExitoso: "Correlativos enviados a su correo electrónico exitosamente",
        rebajarOficios: "Rebajar oficios",
        fechaVencAsignacion: "Fecha venc. asignación",
        fechaVencOficio: "Fecha venc. oficio",
        utilizar: "Utilizar",
        cargarArchivoAdic: "Cargar archivo adicional",
        registroErrores: "Registro de errores",
        cargaOficiosAuto: "Carga de oficios automatizada",
        erroresCargaOficiosAuto: "Errores en carga automatizada oficios",
        controlCiclosAud: "Control de ciclos de auditoría",
        desde: "Desde",
        anios: "Años",
        revisado: "Revisado",
        revisionProcesosCiclo: "Revisión procesos/ciclo",
        importarRevisiones: "Importar revisiones",
        reporteActivosInf: "Reporte activos",
        limitarReporte: "Limitar reporte",
        SeDebeLimitarMapaCalor: "Debe reducir los riesgos a un nivel inferior a 350 para poder generar el reporte",
        usuariosOperativos: "Usuarios operativos",
        usuariosInformativos: "Usuarios informativos",
        usrOperativosDescrip: "Usuarios que se autentican con el sistema y realizan tareas o procesos específicos",
        usrInformativosDescrip: "Usuarios que solo se utilizan como referencia y no necesitan registrarse ni iniciar sesión",
        nuevoUsuarioOperativo: "Nuevo usuario operativo",
        nuevoUsuarioInformativo: "Nuevo usuario informativo",
        convertirEnUsuarioOperativo: "Convertir en usuario operativo",
        convertirEnUsuarioInformativo: "Convertir en usuario informativo",
        organigrama: "Organigrama",
        organigramaEmpresa: "Organigrama de la empresa",
        cargo: "Cargo",
        configSistema: "Configs sistema",
        configCumplimiento: "Configs cumplimiento",
        configRiesgos: "Configs riesgos",
        configActivosInf: "Configs activos de inf.",
        configOficios: "Configs oficios",
        configAuditorias: "Configs auditorías",
        configTimeReport: "Configs timeReport",
        configSistemaDescrip: "Ajustes generales de usuarios, bibliotecas, notificaciones y roles",
        configCumplimientoDescrip: "Ajustes relacionados con documentos, compromisos y actividades",
        configRiesgosDescrip: "Ajustes relacionados con procesos, riesgos y seguimiento",
        configActivosInfDescrip: "Ajustes de tipos, formatos y ubicaciones de activos",
        configOficiosDescrip: "Ajustes de oficios, importadores y rebajadores",
        configAuditoriasDescrip: "Ajustes de ciclos, planes, auditorías y seguimiento",
        configTimeReportDescrip: "Ajustes relacionados al reporte de horas",
        estructura: "Estructura",
        reabrir: "Reabrir",
        seguroDeseaReabrirOficio: "¿Estás seguro de que deseas reabrir la respuesta? Esta acción devolverá la respuesta al ejecutor",
        camposIncompletos: "Campos incompletos",
        sinArchivos: "Sin archivos",
        borrador: "Borrador",
        modifDoc: "Modificación de documento",
        creacionDoc: "Creación de documento",
        solicitudesDocumentos: "Solicitudes de documentos",
        preRevision: "Pre-revisión",
        ratingCriticidadInherente: "Rating criticidad inherente",
        ratingCriticidadResidual: "Rating criticidad residual",
        performanceControles: "Desempeño controles",
        eventosRiesgosPorPeriodo: "Eventos de riesgos por periodo",
        perdidaEventosRiesgosPorPeriodo: "Perdidas eventos por periodo",
        indicadoresPorPeriodo: "Indicadores por periodo",
        busquedaAvanzada: "Búsqueda avanzada",
        solicitarReasignacion: "Solicitar reasignación",
        gestorSolicitudesReasignacion: "Gestor de solicitudes de reasignación",
        ingreseMotivoSolicitud: "Ingrese motivo de solicitud",
        solicitar: "Solicitar",
        solicitudExitosa: "Solicitud enviada exitosamente",
        actInactivaSolicitudReasignacion: "La asignación se habilitará cuando la solicitud de reasignación sea evaluada por visador",
        rechazarYdevolver: "Rechazar y devolver",
        solicitudAprobadaExitosa: "Solicitud aprobada exitosamente",
        solicitudRechazadaExitosa: "Solicitud rechazada exitosamente",
        ejecutorInicial: "Ejecutor inicial",
        seguroDeseaAprobarReasignacion: "¿Está seguro que desea aprobar la reasignación del oficio?",
        seguroDeseaRechazarReasignacion: "¿Está seguro que desea rechazar la reasignación del oficio?",
        justificacion: "Justificación",
        aprobador: "Aprobador",
        fechaAprobacion: "Fecha de aprobación",
        validacionGerencia: "Se debe contar con una gerencia para continuar",
        validacionCampos: "Los campos obligatorios no pueden estar vacíos",
        tiposRiesgosMat: "Tipos de riesgos materializados",
        riesgosMat: "Riesgos materializados",
        procesosAfectados: "Procesos afectados",
        seguroDeseaEliminar: "¿Estás seguro de que deseas eliminar",
        validacionPlantillaCrearEvaluacion: "Se debe contar con una plantilla antes de crear evaluación",
        validacionResponsablesEvaluacion: "Se debe asignar ejecutores/visadores para todos los controles",
        configAssessment: "Configs assessment",
        configAssessmentDescrip: "Ajustes relacionados a plantillas de criterios de evaluación",
        assessment: "Asessment",
        plantillasEvaluaciones: "Plantillas de evaluaciones",
        nuevaEvaluacion: "Nueva evaluación",
        gestorEvaluaciones: "Gestor de evaluaciones",
        preguntas: "Preguntas",
        pregunta: "Pregunta",
        sinElementos: "Sin elementos",
        finalizadas: "Finalizadas",
        misEvaluaciones: "Mis evaluaciones",
        finalizada: "Finalizada",
        criterios: "Criterios",
        puntaje: "Puntaje",
        indiceVcdb: "Índice VCDB",
        puntajeExpectativa: "Puntuación de expectativa",
        impactoMision: "Impacto a la misión",
        impactoObjOp: "Impacto en objs. operativos",
        impactoObligs: "Impacto en las obligaciones",
        puntajeRiesgo: "Puntaje de riesgo",
        nivelRiesgo: "Nivel de riesgo",
        alcance: "Alcance",
        riesgoAbordado: "Riesgo abordado",
        riesgoAceptado: "Riesgo aceptado",
        politicasAprobadas: "Políticas aprobadas",
        controlesImplementados: "Controles implementados",
        controlesAutomatizados: "Controles automatizados",
        controlesReportados: "Controles reportados",
        auditorias: "Auditorías",
        aceptarCompromiso: "Aceptar compromiso",
        editarAuditoria: "Editar auditoría",
        errorFaltaArchivoAdjunto: "Debe ingresar un archivo adjunto para continuar",
        errorNombrePlantilla: "Debe ingresar un nombre de plantilla para continuar",
        errorPlanAuditoria: "Se debe seleccionar un plan de auditoría",
        errorTipoAuditoria: "Se debe seleccionar un tipo de auditoría",
        gestorPlanesAuditoria: "Gestor planes de auditoría",
        gestorAuditorias: "Gestor auditorías",
        misAuditorias: "Mis auditorías",
        misPruebasAuditorias: "Mis pruebas de auditoría",
        misRecomendaciones: "Mis recomendaciones",
        misCompromisos: "Mis compromisos",
        misHoras: "Mis Horas",
        importacionAuditorias: "Importación de auditorías",
        importacionActPreliminares: "Importación de act. preliminares",
        ingreseDescripAdtArea: "Ingrese una descripción para el área de auditoría",
        ingreseDescripAdtTipoAuditoria: "Ingrese una descripción para el tipo de auditoría",
        ingreseDescripAdtTipoTrabajo: "Ingrese una descripción para el tipo de trabajo",
        nuevoTipoAuditoria: "Nuevo tipo de auditoría",
        nuevoTipoTrabajo: "Nuevo tipo de trabajo",
        nuevoAuditoria: "Nueva auditoría",
        tiposTrabajo: "Tipos de trabajo",
        tiposAuditoria: "Tipos de auditoría",
        validacionPlan: "Se debe contar con un plan para continuar",
        sabado: "Sabado",
        domingo: "Domingo",
        controlAdtAreas: "Control de áreas auditoría",
        controlAdtTipoTrabajos: "Control de tipos de trabajo",
        controlAdtTipoAuditorias: "Control de tipos de auditoría",
        listaAdtAreas: "Lista de áreas auditoría",
        listaAdtTiposAuditoria: "Lista de tipos de auditoría",
        listaAdtTiposTrabajo: "Lista de tipos de trabajo",
        anio: "Año",
        tipoAuditoria: "Tipo auditoría",
        tipoTrabajo: "Tipo trabajo",
        fechaPresComite: "Fecha presentación comité",
        horasAsign: "Horas asignadas",
        horasQa: "Horas QA",
        auditorLider: "Auditor lider",
        auditor: "Auditor",
        auditores: "Auditores",
        auditoresQa: "Auditores QA",
        fechaInicioReal: "Fecha inicio real",
        fechaInfPreliminar: "Fecha inf. preliminar",
        fechaInfFinal: "Fecha informe final",
        planAuditoria: "Plan de auditoría",
        plan: "Plan",
        editarPlan: "Editar plan",
        planes: "Planes",
        objetivoGeneral: "Objetivo general",
        docFuenteInfo: "Documentación / Fuente de información",
        actividadesPreliminares: "Act. preliminares",
        iniciarAuditoria: "Iniciar auditoría",
        tipoEvidencia: "Tipo evidencia",
        reqAprobQa: "Req. aprob. QA",
        reqAprobJefatura: "Req. aprob. jefatura",
        reqAprobGerente: "Req. aprob. gerente",
        interaccion: "Interacción",
        seguimiento: "Seguimiento",
        aprobQa: "Aprob. QA",
        aprobJefatura: "Aprob. Jefatura",
        aprobGerente: "Aprob. Gerencia",
        plActPrelimNoConfiguradas: "Plantillas de actividades preliminares no configuradas",
        seguroIniciarAuditoria: "¿Está seguro que desea iniciar la auditoría?",
        hallazgos: "Hallazgos",
        opMejoras: "Oportunidades de mejoras",
        prueba: "Prueba",
        pruebas: "Pruebas",
        completarPrueba: "Completar prueba",
        visarPrueba: "Visar prueba",
        nuevaPrueba: "Nueva prueba",
        editarPrueba: "Editar prueba",
        directas: "Directas",
        indirectaNoCargables: "Indirectas y no cargables",
        totalSemana: "Total semana",
        totalHorasDirectas: "Total horas directas",
        totalHorasIndNoCarg: "Total horas ind./No carg.",
        horasCargar: "Horas a cargar",
        diferencia: "Diferencia",
        recomendacion: "Recomendación",
        sinHallazgos: "Sin hallazgos disponibles",
        recomendaciones: "Recomendaciones",
        jefatura: "Jefatura",
        entregable: "Entregable",
        enRevision: "En revisión",
        compSiendoCursadas: "Compromisos siendo cursados",
        compEsperaAprob: "Compromisos a la espera de aprobación",
        compAunNoHanSidoCerradas: "Compromisos que aún no han sido cerradas",
        compATiempo: "Compromisos a tiempo",
        compAtrasados: "Compromisos atrasados",
        compVisadosAtiempo: "Compromisos visados a tiempo",
        compVisadosConRetraso: "Compromisos visados con retraso",
        compEsperaSerVisados: "Compromisos en espera ser visados",
        compQueSeEncuentranAtrasados: "Compromisos que se encuentran atrasados",
        compTotRechazados: "Compromisos totales rechazados",
        seguroDeseaEliminarPrueba: "¿Estás seguro de que deseas eliminar la prueba",
        seguroDeseaAprobar: "¿Estás seguro de que deseas aprobar?",
        seguroDeseaDevolverEjecutor: "¿Estás seguro de que deseas devolver al ejecutor?",
        gestorReglasAuditoria: "Gestor de reglas de auditoría",
        nuevaRegla: "Nueva regla",
        alertasNuevas: "Alertas nuevas",
        nombreRegla: "Nombre de regla",
        regla: "Regla",
        detalleRegla: "Detalle de regla",
        descargarRegla: "Descargar regla",
        reglaCritica: "Regla crítica",
        reglaNormal: "Regla normal",
        evolucionRegla: "Evolución de regla",
        vistaPreviaRegla: "Vista previa de regla",
        tipoRegla: "Tipo de regla",
        reporteReglasAuditoria: "Reporte de reglas auditoría",
        misReglasAuditoria: "Mis reglas de auditoría",
        reglasPorRevisar: "Reglas por revisar",
        ingreseCodigo: "Ingrese codigo",
        importacionReglasAdt: "Importación reglas de auditoría",
        seguroDeseaEnviarSolicitudProrroga: "¿Estás seguro de que deseas enviar la solicitud de prórroga?",
        solicitudesProrrogasCompromisos: "Solicitudes prórrogas de compromisos",
        evaluar: "Evaluar",
        visarProroga: "Visar prórroga",
        aprobarYmodificar: "Aprobar y modificar",
        modificar: "Modificar",
        apruebaCambiandoFechaPropuesta: "Aprueba cambiando fecha propuesta",
        apruebaProrroga: "Aprueba la prórroga",
        rechazaProrroga: "Rechaza la prórroga",
        compRequeridoContinuar: "Se requiere seleccionar un compromiso antes de continuar",
        misSolicitudesProrroga: "Mis solicitudes de prórroga",
        fechaEvaluacionComite: "Fecha evaluación comité",
        configFirma: "Config. firma",
        seguroDeseaEliminarPlantilla: "¿Estás seguro de que deseas eliminar la plantilla",
        propuesta: "Propuesta",
        revisores: "Revisores",
        visadores: "Visadores",
        debeSeleccionarRevisores: "Debe seleccionar al menos un revisor",
        debeSeleccionarVisadores: "Debe seleccionar al menos un visador",
        version: "Versión",
        rechazaYdevuelveActAlSolicitante: "Rechazar y devolver al solicitante",
        rechazarSolicitante: "Devolver a solicitante",
        cargarNuevaVersionDoc: "¿Cargar nueva versión del documento?",
        efecto: "Efecto",
        marcoNormativo: "Marco normativo",
        documentarPapelTrabajo: "Documentar papel de trabajo",
        auditoriasDirectas: "Auditorías directas",
        horasDirIndNoCarg: "Horas directas, indirectas y no cargables",
        gerenteAuditoria: "Gerente auditoría",
        gerenciaContraparte: "Gerencia contraparte",
        gerenteContraparte: "Gerente contraparte",
        aprobAuditorLider: "Aprob. Auditor líder",
        actFormaParteInteracion: "Actividad es obligatoria debido a que forma parte de una interacción",
        misHallazgos: "Mis hallazgos",
        hallazgo: "Hallazgo",
        rechazado: "Rechazado",
        accionRequerida: "Acción requerida",
        auditoriaSinQa: "Auditoría no cuenta con auditores QA",
        papelTrabajo: "Papel de trabajo",
        detalleCambios: "Detalle de cambios",
        cargarArchivo: "Cargar archivo",
        suplente: "Suplente",
        administradorCumplimiento: "Admin cumplimiento",
        administradorOficios: "Admin oficios",
        administradorRiesgos: "Admin riesgos",
        administradorAuditoria: "Admin auditoría",
    }
};

export default es_localization;