import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initialState } from './contexto/initialState';
import { StateProvider } from './contexto/store';
import { mainReducer } from './contexto/reducers';
import CacheBuster from 'react-cache-buster';
import { Toaster } from 'react-hot-toast';
import LocalizationProvider from 'contexto/localization/LocalizationProvider';

const version = "1.9.137";
const isProduction = true;

ReactDOM.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<div>Espere un momento...</div>}
      metaFileDirectory={'.'}
    >
      <StateProvider initialState={initialState} reducer={mainReducer}>
        <LocalizationProvider>
          <div>
            <Toaster
              position="bottom-center"
              toastOptions={{
                success: {
                  style: {
                    background: '#22bb33',
                    color: '#ffffff',
                  },
                },
                error: {
                  style: {
                    background: '#bb2124',
                    color: '#ffffff',
                  },
                },
                loading: {
                  style: {
                    background: '#2285bb',
                    color: '#ffffff',
                  },
                },
              }}
            />
          </div>
          <App versionApp={version} />
        </LocalizationProvider>
      </StateProvider>
    </CacheBuster>
  </React.StrictMode>,
  document.getElementById('root')
);
